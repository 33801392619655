/* ==========================================================================
   MASTHEAD
   ========================================================================== */

.masthead {
  position: relative;
  border-bottom: 1px solid #eee;
  -webkit-animation: $intro-transition;
          animation: $intro-transition;
  -webkit-animation-delay: 0.15s;
          animation-delay: 0.15s;
  z-index: 20;

  &__inner-wrap {
    @include clearfix;
    margin-left: auto;
    margin-right: auto;
    padding: 1em;
    max-width: 100%;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    font-family: $sans-serif-narrow;

    @include breakpoint($x-large) {
      max-width: $x-large;
    }

    nav {
      z-index: 10;
    }

    a {
      text-decoration: none;
    }
  }
}

.site-title {
  display: -webkit-box;
  display: flex;
  padding: 0.5rem 0;
  align-self: stretch;
  -webkit-box-align: center;
        align-items: center;
  font-weight: bold;
  z-index: 20;
  text-transform: capitalize;
}

.masthead__menu {
  float: left;
  margin-left: 0;
  margin-right: 0;
  width: 100%;
  clear: both;

  .site-nav {
    margin-left: 0;

    @include breakpoint($small) {
      float: right;
    }
  }

  ul {
    margin: 0;
    padding: 0;
    clear: both;
    list-style-type: none;
  }
}

.masthead__menu-item {
  display: block;
  list-style-type: none;
  white-space: nowrap;

  &--lg {
    padding-right: 2em;
    font-weight: 700;
  }
}

.button {
  &_more {
    color: rgba(26, 137, 23, 1);
    font-weight: 300;
    display: block;
    margin: 36px auto 0;
    width: 10rem;
    background: #eeeeee;
    padding: 8px;
    text-align: center;
    border-radius: 5px;
    font-size: 18px;
  }
}