.blog{
  &_card {
    display: block;
    max-width: 100%;
    text-decoration: none;
    padding: 20px;
    transition: all 0.3s;
    &:hover{
      box-shadow: 0 0 50px rgba(0,0,0,0.17);
      border: 5px;
    }
  }
  &_excerpt{
    filter: drop-shadow(-90px 50px 50px rgba(52,180,64,0.3));
    margin-bottom: 50px; 
  }
  &_header {
    text-transform: capitalize;
    margin-top: 20px;
  }
}