@charset "utf-8";

@import "minimal-mistakes/skins/dirt"; // skin
@import "minimal-mistakes"; // main partials

.profile {
  &_icon {
    --length: 25px;
    display: inline-block;
    height: var(--length);
    width: var(--length) !important;
  }
}